import React from 'react'
import Layout from '../Layout'
import View from '../View'
import { getDistributionGroups } from '../../utils/email'
import { Table } from '../Table'
import Moment from 'moment'
import { Link } from 'gatsby'
import WaitLoader from '../WaitLoader'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'

class DistributionLists extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groups: [],
      errorMessage: '',
      loading: true,
      showRefreshMessage: false
    }
  }

  componentDidMount() {
    this.getGroups()
  }

  getGroups() {
    this.setState({
      loading: true
    },function(){
      getDistributionGroups(function(err, response){
        if(err){
          console.log(err)
          this.setState({errorMessage: err.message, loading: false})
        }else{
          console.log(response)
          response.forEach((item, i) => {
            item.readyToSend = item.ValidEmailsCount - item.PriorEmailedCount
          });
          let groups = response
          if(!groups){
            groups = []
          }
          this.setState({ groups: groups, loading: false })
        }
      }.bind(this))
    })
  }

  render() {
    const logColumns = [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'Name'
      },
      {
        Header: 'Contacts',
        accessor: 'MembersCount'
      },
      {
        Header: 'Targetable',
        accessor: 'readyToSend'
      },
      {
        id: 'actions',
        Header: 'Actions',
        accessor: d => d.Id,
        Cell: props => <div>
          <a className="button"
          href={'https://q.forms-surfaces.com/SalesEmailDistributionGroupDetails/'+props.value}
          target="_blank"
          onClick={(e)=>{this.setState({showRefreshMessage: true})}}
          >
          Edit List
          </a>
        </div>
      }
    ]
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    return (
      <Layout>
      <View title="YOUR DISTRIBUTION LISTS">
      <a className="button newListButton"
      href={'https://q.forms-surfaces.com/MySalesEmailDistGroup?new'}
      target="_blank"
      onClick={(e)=>{this.setState({showRefreshMessage: true})}}>
      New List
      </a>
      <div className="row col-xs-12">
      <p>Manage your distribution lists, starting with clicking on 'New List' to create a new one. Click on 'Edit List' to manage any of your saved distribution lists. Each action will redirect you to Q to complete the corresponding process.</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12 relative">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          <Table
            data={this.state.groups}
            columns={logColumns}
            defaultPageSize={10}
            minRows={3}
          />
        </div>
      </div>
      <Dialog
        className="standard-dialog"
        open={this.state.showRefreshMessage}
        onClose={() => this.setState({
          showRefreshMessage: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've opened a new window to edit your distribution lists. Please refresh to reload this content.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="underline-button" onClick={() => this.setState({
            showRefreshMessage: false})}>Cancel</Button>
          <Button variant="contained" onClick={(e)=>{
          this.setState(
            {showRefreshMessage: false},
            function(){
              this.getGroups()
            })
          }}>
            Reload Lists
          </Button>
        </DialogActions>
      </Dialog>
      </View>
      </Layout>
    )
  }
}

export default DistributionLists
