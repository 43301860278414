import React from 'react'
import DistributionLists from '../../components/Email/DistributionLists'
import FSRoute from '../../components/FSRoute'
import { isBrowser } from '../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <FSRoute
        path="/email/distribution-lists"
        component={DistributionLists}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App
